import React, { useRef, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Avatar,
  HStack,
  VStack,
  Icon,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import Carousel from "../../common/Carousal";
import { useQuery } from "@tanstack/react-query";
import { getGoogleReviews } from "../../../api/google-reviews";

const ReviewCard = ({ name, avatar, rating, review }) => {
  const truncatedReview = review.length > 412 ? `${review.substring(0, 412)}...` : review;

  return (
    <Box
      bg="white"
      p={[4, 5, 6]}
      mx={{ base: 0, md: 3 }}
      borderRadius="20px"
      boxShadow="lg"
      maxW={{ base: "320px", md: "400px" }}
      minH="430px"
      textAlign="left"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      _hover={{ boxShadow: "xl", transform: "scale(1.02)" }}
      transition="all 0.2s ease-in-out"
    >
      <Box>
        <HStack mb={4} spacing={3}>
          <Avatar name={name} src={avatar} size="lg" />
          <VStack align={{ base: "center", md: "start" }} spacing={0}>
            <Text fontSize="md" fontWeight="bold">
              {name}
            </Text>
            <HStack spacing={1}>
              {[...Array(5)].map((_, i) => (
                <Icon
                  key={i}
                  as={FaStar}
                  color={i < rating ? "yellow.400" : "gray.300"}
                />
              ))}
            </HStack>
          </VStack>
        </HStack>
        <Text mb={4} fontSize="md" fontWeight="bold">
          "{truncatedReview}"
        </Text>
      </Box>
    </Box>
  );
};

const GoogleRatings = () => {
  const carouselRef = useRef();

  const { data: reviewsData, isLoading, isError, refetch } = useQuery({
    queryKey: ["googleReviews"],
    queryFn: () => getGoogleReviews({}),
    select: (res) => res?.data?.records.filter((review) => review.isActive),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 10000);
    return () => clearInterval(interval); 
  }, [refetch]);

  if (isLoading) {
    return (
      <Center minH="200px">
        <Spinner size="xl" thickness="4px" color="#ECC94B" speed="0.65s" />
      </Center>
    );
  }

  if (isError || !reviewsData) {
    return (
      <Text
        mb={10}
        ml={20}
        pl={20}
        mt={10}
        textAlign="left"
        color="red.500"
      >
        Error loading reviews or rating.
      </Text>
    );
  }

  return (
    <Box mx="auto" pb={6}>
      <Flex justifyContent="center" alignItems="center" position="relative" mx="auto">
        <Carousel cardWidth={370} cardMargin={0} ref={carouselRef}>
          {reviewsData.map((review, index) => (
            <ReviewCard
              key={index}
              name={review?.reviewerName}
              avatar={review?.reviewerProfilePhoto}
              rating={review?.starRating}
              review={review?.comment}
            />
          ))}
        </Carousel>
      </Flex>
    </Box>
  );
};

export { GoogleRatings, ReviewCard };
