/** @format */

import React, { useRef, useEffect, useCallback, Suspense, lazy } from "react";
import Button from "antd/es/button";
import "../../styles/home.css";
import HomeContectUs from "../../components/Home/HomeContectUs";
import ExploreApartments from "../../components/PropertyTypeSection";
import { IconButton, Flex } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import ExploreTowns from "../../components/ExploreTowns";
import GoogleRatingsAndReviews from "../../components/GoogleReviews";
import CallToAction from "../../components/Home/CallToAction";
import { useLocation } from "react-router-dom";
import {
  HandleArabicClick,
  HandleEnglishClick,
} from "../../utils/Helper";
import Header from "../../components/Header";
import { FormattedMessage } from "react-intl";
import SearchBar from "../../components/Home/SearchBar";
const Property = lazy(() => import("../../components/Home/Property"));
const Feature = lazy(() => import("../../components/Home/Feature"));
const Blogs = lazy(() => import("../../components/Home/Blogs"));
const Testimonial = lazy(() => import("../../components/Testimonials/Testimonial"));

const Home = () => {
  const location = useLocation();
  const exploreTownsRef = useRef(null);
  const scrollButtonRef = useRef(null);

  useEffect(() => {
    if (location.state?.scrollTo === "ExploreTowns" && exploreTownsRef.current) {
      exploreTownsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.state]);

  const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

  const handleScroll = useCallback(() => {
    if (!scrollButtonRef.current) return;

    if (window.scrollY > 200) {
      scrollButtonRef.current.style.display = "flex";
    } else {
      scrollButtonRef.current.style.display = "none";
    }
  }, []);

    useEffect(() => {
      if ("requestIdleCallback" in window) {
        requestIdleCallback(() => {
          if (scrollButtonRef.current) {
            scrollButtonRef.current.style.display = window.scrollY > 200 ? "flex" : "none";
          }
        });
      } else {
        setTimeout(() => {
          if (scrollButtonRef.current) {
            scrollButtonRef.current.style.display = window.scrollY > 200 ? "flex" : "none";
          }
        }, 100);
      }
    
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);

  let Language;
  const lang = localStorage.getItem("lang");
  if (lang) {
    Language = "ar";
  } else {
    Language = "en";
  }
  return (
    <div>
      <div className="header-wrapper">
        <Header />
      </div>
      <div className="home-section-1">
        <div className="language_button_position">
          <Button.Group>
            <Button
              className={Language === "en" ? "enClass" : "arClass"}
              onClick={HandleEnglishClick}
            >
              English
            </Button>
            <Button
              className={Language === "ar" ? "enClass" : "arClass"}
              onClick={HandleArabicClick}
            >
              عربي
            </Button>
          </Button.Group>
        </div>

        <div className="home-heading-container">
          <p>
            <FormattedMessage id="find_your_dream" />
          </p>
        </div>
        <div className="home-paragraph-container">
          <p>
            <FormattedMessage id="home_welcome" />
          </p>
        </div>

        <SearchBar />
      </div>
      <Suspense fallback={<div>Loading Property Component...</div>}>
        <Property />
      </Suspense>

      <ExploreApartments />

      <div style={{padding: 0}} ref={exploreTownsRef}>
        <ExploreTowns />
      </div>

      <CallToAction />

      <Suspense fallback={<div>Loading Feature Component...</div>}>
        <Feature />

        <Testimonial />

        <Blogs />
      </Suspense>

      <GoogleRatingsAndReviews /> 

      <HomeContectUs />

        <div ref={scrollButtonRef} style={{ display: "none" }}>
        <Flex
          position="fixed"
          bottom="30px"
          right="30px"
          zIndex="1000"
          align="center"
          justify="center"
        >
          <IconButton
            icon={<ArrowUpIcon />}
            onClick={scrollToTop}
            colorScheme="yellow"
            aria-label="Scroll to top"
          />
        </Flex>
        </div>
    </div>
  );
};

export default Home;
