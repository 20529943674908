import { Axios } from "../../utils/axiosMiddleware";
import { appendQueryParam } from "../../utils/Helper";

export const getGoogleReviews = (queryParams) => {
  let url = `google-reviews`;
  url = appendQueryParam(url, queryParams);

  return Axios.get(`${url}`);
};

